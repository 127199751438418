export const sectionVar = {
  i: {},
  v: {
    transition: {
      staggerChildren:0.5
    }
  },
  o: {}
}

export const fadeNorth = {
  i: {
    opacity:0,
    y:"2rem"
  },
  v: {opacity:1, y:0},
  o: {opacity:0, y:"-2rem"}
}

export const fadeEast = {
  i: {
    opacity:0,
    x:"-6rem"
  },
  v: {opacity:1, x:0},
  o: {opacity:0, x:"6rem"}
}

export const fadeWest = {
  i: {
    opacity:0,
    x:"6rem"
  },
  v: {opacity:1, x:0},
  o: {opacity:0, x:"-6rem"}
}