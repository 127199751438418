import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { motion, MotionConfig } from "framer-motion";
import InView from "react-intersection-observer";
import { sectionVar, fadeEast, fadeWest } from "../data/transitions";

import {
  SuperTitle,
  HeroSubtitle,
  HeroTitle,
} from "../components/components/PageElements";
import Seo from "../components/layout/Seo";

import { heroHolder } from "../components/components/PageElements.module.scss";
import { ourTeamHolder } from "./our-team.module.scss";

import DownArrow from "../images/svgs/DownArrow.svg";

const arrowBounce = {
  animate: {
    y: [10, 0, 10, 0, 10],
    transition: {
      repeat: Infinity,
      repeatDelay: 3,
      bounce: 0.7,
      type: "spring",
      mass: 2,
    },
  },
};

export default function OurTeamPage() {
  const data = useStaticQuery(graphql`
    query ActivityDataQuerys {
      allWpTeamMember(sort: { order: ASC, fields: menuOrder }) {
        nodes {
          title
          id
          slug
          categories {
            nodes {
              id
              name
            }
          }
          memberInfo {
            bio
            contactEmail
            role
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const teamMembers = data.allWpTeamMember.nodes;

  return (
    <MotionConfig transition={{ duration: 0.6, type: "tween" }}>
      <article className={ourTeamHolder}>
        <Seo
          title="Meet the team"
          description="Members of staff at Pygmalion Capital."
        />

        <section className={heroHolder}>
          <div className="lrHolder">
            <div className="left fullHeight">
              <StaticImage
                src="../images/pages/team/team-hero-05.jpg"
                alt="Pygmalion Capital - Meet The Team"
                quality={80}
                width={900}
                className="roundedCorners wider"
                objectFit="contain"
              />
            </div>
            <div className="right">
              <div className="inner">
                <SuperTitle title="Meet Pygmalion" inView={true} />
                <HeroTitle>Our Team</HeroTitle>
                <HeroSubtitle>
                  Founded by Christophe Beauvilain, Pygmalion Capital is led by
                  highly seasoned hotel investment professionals who have
                  successfully navigated all stages of the European real estate
                  investment cycle. We are a collaborative, diverse team based
                  in London and Barcelona with deep operational expertise, a
                  commitment to analytic rigor, and a shared passion for real
                  estate.{" "}
                </HeroSubtitle>
                <AnchorLink to="/our-team#meet" className="meetLink">
                  <div className="diveIn">Meet the team</div>
                  <motion.div variants={arrowBounce} animate="animate">
                    <DownArrow />
                  </motion.div>
                </AnchorLink>
              </div>
            </div>
          </div>
        </section>

        <ul className="members">
          {teamMembers.map((member) => {
            const image = getImage(member.featuredImage?.node.localFile);

            return (
              <InView threshold={0.25} key={member.id} triggerOnce={true}>
                {({ ref, inView }) => (
                  <motion.li
                    id="meet"
                    className="member"
                    ref={ref}
                    variants={sectionVar}
                    initial="i"
                    animate={inView ? "v" : "i"}
                    exit="o"
                  >
                    <motion.div className="memberImage" variants={fadeEast}>
                      {image && (
                        <GatsbyImage image={image} alt={member.title} />
                      )}
                    </motion.div>
                    <motion.div className="memberInfo" variants={fadeWest}>
                      <h5>{member.title}</h5>
                      {member.memberInfo.role && (
                        <div className="role">{member.memberInfo.role}</div>
                      )}
                      {member.memberInfo.contactEmail && (
                        <a
                          href={`mailto:${member.memberInfo.contactEmail}`}
                          title={`Email ${member.memberInfo.title}`}
                          className="email"
                        >
                          {member.memberInfo.contactEmail}
                        </a>
                      )}
                      {member.memberInfo.bio && <p>{member.memberInfo.bio}</p>}
                    </motion.div>
                  </motion.li>
                )}
              </InView>
            );
          })}
        </ul>
      </article>
    </MotionConfig>
  );
}
